import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import usePagination from '../../pagination/usePagination'

export default function useSourceDataList() {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'sourceTitle', sortable: true },
    { key: 'sourceDescription', sortable: true },
    { key: 'sourceDataType', sortable: true },
    { key: 'actions', class: 'text-right' },
  ]

  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const sourceDataType = ref(null)

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([searchQuery, sourceDataType], () => {
    refetchData()
  })

  const { perPage, currentPage, totalItems, dataMeta, perPageOptions } = usePagination(refetchData)

  const fetchList = (ctx, callback) => {
    store
      .dispatch('sourceDataManagement/fetchSourceDatas', {
        Search: searchQuery.value,
        PageSize: perPage.value,
        PageIndex: currentPage.value,
        sortBy: sortBy.value,
        isSortDirDesc: isSortDirDesc.value,
        sourceDataType: sourceDataType.value,
      })
      .then(response => {
        const { data, count } = response.result
        callback(data)
        totalItems.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Source Data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveSourceType = type => {
    const types = {
      0: 'None',
      1: 'Periodical/Region',
      5: 'PCA/Region',
      2: 'Modern Proximity',
      3: 'Ancient Proximity',
      4: 'Ancient Mixed Mode',
      7: 'Modern Mixed Mode',
      6: 'Farmer & HGs',
      8: 'Custom Ancestry Break Down',
      9: 'Custom Modern Break Down',
    }
    return types[type] || 'None'
  }

  return {
    fetchList,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    sourceDataType,
    resolveSourceType,
    refetchData,
  }
}
