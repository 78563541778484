<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2 mt-2">
        <b-row>
          <b-col cols="12" md="8">
            <source-data-filter
              :search-query.sync="searchQuery"
              :sourceDataType.sync="sourceDataType"
            />
          </b-col>
          <b-col cols="12" md="4" class="mt-2">
            <div class="d-flex align-items-center justify-content-end">
              <crud-button :onClickRefresh="refetchData" :onClickAddNew="addUser" />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Table and Pagination -->
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: SourceDataType -->
        <template #cell(sourceDataType)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveSourceType(data.item.sourceDataType) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-right">
            <feather-icon
              :id="`user-row-${data.item.id}-edit-icon`"
              @click="$router.push({ name: 'manage-source-data-save', params: { id: data.item.id } })"
              icon="EditIcon"
              class="cursor-pointer text-primary"
              size="16"
            />
            <feather-icon
              :id="`user-row-${data.item.id}-remove-icon`"
              @click="deleteUser(data.item.id)"
              icon="Trash2Icon"
              class="cursor-pointer mx-1 text-danger"
              size="16"
            />
          </div>
        </template>
      </b-table>

      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <pagination-component
          :per-page="perPage"
          :current-page.sync="currentPage"
          :total-items="totalItems"
          :meta="dataMeta"
          @page-changed="refetchData"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import SourceDataFilter from './SourceDataFilter.vue'
import useSourceDataList from './useSourceDataList'
import PaginationComponent from '../../pagination/PaginationComponent.vue'

export default {
  components: {
    SourceDataFilter,
    PaginationComponent,
  },
  methods: {
    addUser() {
      this.$router.push({ name: 'manage-source-data-save', params: { id: 0 } })
    },
    deleteUser(id) {
      this.$store.dispatch('sourceDataManagement/removeSourceData', { id })
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast.success(this.$t(response.message))
            this.refetchData()
          } else {
            this.$toast.error(this.$t(response.message))
          }
        })
    },
  },
  setup() {
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      sourceDataType,
      resolveSourceType,
      refetchData,
    } = useSourceDataList()

    return {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      sourceDataType,
      resolveSourceType,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
