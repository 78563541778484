<template>
    <div class="d-flex align-items-center">
      <div class="mr-2 filter-select">
        <label>{{ $t('Source Data Type') }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="sourceDataType"
          :options="sourceTypeOptions"
          class="w-100"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:sourceDataType', val)"
        />
      </div>
      <div class="d-flex align-items-center justify-content-end mt-2">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            :value="searchQuery"
            @input="(val) => $emit('update:searchQuery', val)"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
        </b-input-group>
      </div>
    </div>
</template>

<script>
// Options
import StaticOptions from '@/common/options/StaticOptions'

export default {
  props: {
    searchQuery: {
      type: String,
      default: null,
    },
    sourceDataType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      sourceTypeOptions: StaticOptions.sourceTypeOptions,
    }
  },
}
</script>
<style lang="scss" scoped>
.filter-select {
  min-width: 240px;
}
</style>
